import React from 'react';
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <div className="footer-container">
      <a href="https://www.instagram.com/meltingbitesllc" target="_blank" rel="noopener noreferrer" className="footer-link">
        Follow us on Instagram
      </a>
      <a href="mailto:meltingbites00@gmail.com" className="footer-link">
        Email us at: meltingbites00@gmail.com
      </a>
    </div>
  );
}

export default Footer;
