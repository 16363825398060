import React from 'react';
import './App.css';
import Footer from './components/Footer';

const App = () => {
  return (
    <div className="construction-container">
      <h1 className="construction-title">Our Website is Under Construction</h1>
      <p className="construction-text">We're working hard to give you the best experience possible. Please check back soon!</p>
      <Footer/>
    </div>
  );
}

export default App;
